'use client'

import React, {
  createContext,
  useContext,
  Context,
  useEffect,
  useState
} from 'react'
import { usePathname } from 'next/navigation'
import { adDataStructure } from 'data/ads'
import useTimeout from '../lib/useTimeout'

type ContextType = {
  isApp: boolean | undefined
  sasReady: boolean
  adPageId: string | undefined
  forceAdRefresh: number
  setKeyword: React.Dispatch<React.SetStateAction<string | undefined>>
}

const AdDataContext: Context<ContextType> = createContext<ContextType>({
  isApp: undefined,
  sasReady: false,
  adPageId: undefined,
  forceAdRefresh: 0,
  setKeyword: () => undefined
})

export const useAdData = (): ContextType => useContext(AdDataContext)

export const AdDataProvider = AdDataContext.Provider

const getAdPageIds = (): any => {
  // no more splitting by desktop/mobile, same page ids for both
  // const isMobile = window.nativePushStatus || window.nativeRequestPushToken
  // return isMobile ? adDataStructure.mobile : adDataStructure.desktop

  return adDataStructure
}

const getAdPageIdByKey = (adPageIds: any, key?: string) => {
  return key && adPageIds[key] ? adPageIds[key] : adPageIds.default
}

const getDefaultAdPageId = (): string => {
  const adPageIdsData = getAdPageIds()
  return getAdPageIdByKey(adPageIdsData.default.adPageIds)
}

const getDefaultNewsPageId = (): string => {
  const adPageIdsData = getAdPageIds()
  return getAdPageIdByKey(adPageIdsData.news.adPageIds)
}

const getTournamentAdPageIdWithDefault = (
  path: string,
  routeParts: string[]
): string | undefined => {
  const adPageIdsData = getAdPageIds()

  const tScope = routeParts[0] // `leagues` or `tournaments`
  const tournamentId = routeParts[1]

  const tournamentAdPageIdsData = adPageIdsData[tScope][tournamentId as string]
    ? adPageIdsData[tScope][tournamentId as string].adPageIds // specific
    : adPageIdsData[tScope].adPageIds // fallback

  let adPageId: string | undefined = undefined

  // map page to available pattern
  if (tournamentAdPageIdsData) {
    if (path.endsWith('/spielplan')) {
      adPageId = tournamentAdPageIdsData['schedule']
    }

    if (path.endsWith('/alle-spiele')) {
      adPageId = tournamentAdPageIdsData['all-games']
    }

    if (path.endsWith('/tabelle')) {
      adPageId = tournamentAdPageIdsData['table']
    }

    if (path.endsWith('/statistiken')) {
      adPageId = tournamentAdPageIdsData['statistics']
    }

    if (path.includes('/spiele/')) {
      adPageId = tournamentAdPageIdsData['game']
    }
  }

  // fallback to `leagues` or `tournaments` default
  if (!adPageId) {
    adPageId = tournamentAdPageIdsData['default']
  }

  // fallback to global default
  if (!adPageId) {
    adPageId = adPageIdsData.default.adPageIds.default
  }

  return adPageId
}

const getAdPageIdNews = (
  path: string,
  routeParts: string[],
  keyword?: string
) => {
  const adPageIdsData = getAdPageIds()

  const isTagPage = routeParts[1] ? routeParts[1] === 'tags' : false
  const tagSlugs = isTagPage && routeParts[2]

  keyword = isTagPage ? (tagSlugs as string) : keyword
  const keywordList = Object.keys(adPageIdsData.news)

  const matchingKeywords = keywordList.filter(
    (word: string) => keyword && keyword.includes(word)
  )
  const matchingKeyword = matchingKeywords[0]

  return matchingKeyword && adPageIdsData.news[matchingKeyword]?.adPageIds
    ? getAdPageIdByKey(adPageIdsData.news[matchingKeyword].adPageIds)
    : getDefaultNewsPageId()
}

const getAdPageId = (path: string, routeParts: string[]) => {
  const adPageIdsData = getAdPageIds()

  return adPageIdsData[routeParts[1]]?.adPageIds
    ? getAdPageIdByKey(adPageIdsData[routeParts[0]].adPageIds)
    : getDefaultAdPageId()
}

const getAdPageIdFromRoute = (
  path: string,
  keyword?: string
): string | undefined => {
  const routeParts = path?.split('/').slice(1)
  if (!routeParts || routeParts?.length < 1) {
    return undefined
  }

  if (['ligen', 'wettbewerbe'].includes(routeParts[0])) {
    return getTournamentAdPageIdWithDefault(path, routeParts)
  }

  if (routeParts[0] === 'news') {
    return getAdPageIdNews(path, routeParts, keyword)
  }

  return getAdPageId(path, routeParts)
}

interface IPageWrapperProps {
  children: React.ReactNode
}

const AdWrapper: React.FC<IPageWrapperProps> = ({ children }) => {
  const path = usePathname() || '/'

  const [forceAdRefresh, setForceAdRefresh] = useState<number>(0)
  const [adPageId, setAdPageId] = useState<string | undefined>(undefined)
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const [sasReady, setSasReady] = useState<boolean>(false)
  const [isApp, setIsApp] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const newPageId = getAdPageIdFromRoute(path, keyword)
    if (newPageId !== adPageId) {
      setAdPageId(newPageId)
    } else {
      setForceAdRefresh((old) => old + 1)
    }
  }, [path, keyword])

  useEffect(() => {
    const checkSasInterval = setInterval(() => {
      if (window.sas) {
        window.sas.cmd.push(function () {
          try {
            window.sas.setup({
              networkid: 4104,
              domain: 'https://www3.smartadserver.com',
              async: true
            })
          } catch (error) {
            // surpressing the race condition error of multiple setups
            if (
              error instanceof Error &&
              !error.message.includes('sas.setup can only be called once')
            ) {
              console.log('sas setup error', error)
            }
          }
        })

        setSasReady(true)
        clearInterval(checkSasInterval as unknown as number)
      }
    }, 100)
    return () => clearInterval(checkSasInterval as unknown as number)
  }, [])

  useTimeout(() => {
    if (window.nativePushStatus || window.nativeRequestPushToken) {
      setIsApp(true)
    } else {
      setIsApp(false)
    }
  }, 100)

  const value: ContextType = {
    isApp,
    sasReady,
    adPageId,
    forceAdRefresh,
    setKeyword
  }

  return <AdDataProvider value={value}>{children}</AdDataProvider>
}

export default AdWrapper
