'use client'

/* eslint-disable no-tabs */
import { useAdData } from 'context/AdRouteContext'
import React, {
  ComponentType,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { isAndroid } from 'react-device-detect'
import { isTabletScreen } from 'shared/domHelpers'
import { isDevEnv } from 'shared/envHelpers'

declare global {
  interface Window {
    sas: any
    nativePushStatus: 'unsupported' | 'granted' | 'denied' | 'default'
  }
}

type AdComponentProps = ComponentType<{
  adId?: string
  adPageId?: string
  sticky: boolean
  formatId?: string
  hideMargin?: boolean
  hideTopMargin?: boolean
  hideBottomMargin?: boolean
}>

const AdComponent: AdComponentProps = ({
  adId,
  adPageId,
  formatId,
  sticky,
  hideTopMargin,
  hideBottomMargin,
  hideMargin
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showMargin, setShowMargin] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    if (!sticky) return

    const handleScroll = () => {
      const bottomStyle = (element: HTMLElement | null) => {
        if (element)
          element.style.bottom =
            ref?.current?.clientHeight === 0
              ? `calc(1rem + env(safe-area-inset-bottom + var(--tabBarHeight)))`
              : `calc(${ref?.current?.clientHeight}px + 12.5px + var(--tabBarHeight))`
      }

      const scrollToTopButton: HTMLElement | null =
        document.querySelector('.scroll-to-top')
      const cmpboxrecall = document.getElementById('cmpboxrecall')
      bottomStyle(scrollToTopButton)
      bottomStyle(cmpboxrecall)
    }
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    if (!ref.current) return

    const resizeObserver = new ResizeObserver(() => {
      setShowMargin(ref?.current?.clientHeight !== 0)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [])

  const classNames = useMemo(
    () => `
      flex justify-center w-full ad-page-id_${adPageId} ad-format-id_${formatId}
      ${
        sticky
          ? 'max-h-[80px] fixed bottom-[var(--tabBarHeight)] left-0 right-0 z-10 bg-white shadow-[rgba(0,0,0,0.1)_0px_-4px_6px_1px,rgba(0,0,0,0.1)_0px_-2px_4px_2px]'
          : showMargin && !hideMargin
          ? 'my-5 md:my-10'
          : (hideTopMargin ? 'mb-5 md:mb-10' : '') +
            (hideBottomMargin ? 'mt-5 md:mt-10' : '')
      }
    `,
    [sticky, showMargin, hideMargin, hideTopMargin, hideBottomMargin]
  )

  // FIXME : this still does not work on production
  //         not really a big issue here, might be for other apis though...
  // const isDevEnv = isDevEnv()

  return (
    <div className={classNames} id={adId} ref={ref}>
      {isDevEnv() && (
        <>
          BANNER PLACEHOLDER formatID: {formatId}, pageID: {adPageId}
        </>
      )}
    </div>
  )
}

export type AdBaseProps = {
  desktop?: boolean | string
  mobile?: boolean | string
  sticky?: boolean
  hideMargin?: boolean
  hideTopMargin?: boolean
  hideBottomMargin?: boolean
  adPageId?: string
}

export type AdProps = ComponentType<
  {
    formatId: string
  } & AdBaseProps
>

const DEFAULT_SITE_ID = 602515
const ANDROID_SITE_ID = 604040
const IOS_SITE_ID = 604041

export const adFormats = {
  leaderBoard: {
    header: '123897',
    default: '123898',
    selfPromo: '133632',
    sticky: '123902',
    footer: '123898',
    home1: '124410',
    home2: '124411',
    home3: '124412',
    home4: '124413'
  },
  rectangle: {
    mediumDefault: '123900',
    mediumSelfPromo: '123903',
    mediumHome1: '124415'
  },
  halfPage: { default: '123899' },
  other: { intersticial: '123901', parallax: '129376' }
}

const Ad: AdProps = ({
  formatId,
  sticky = false,
  hideMargin,
  hideTopMargin,
  hideBottomMargin,
  adPageId: forceAdPageId
}) => {
  const { adPageId, sasReady, isApp, forceAdRefresh } = useAdData()
  const adId = 'sas_' + uuidv4()
  const correctPageId = forceAdPageId ?? adPageId

  useEffect(() => {
    if (sasReady) {
      const sas = window.sas

      const siteId = isApp
        ? isAndroid
          ? ANDROID_SITE_ID
          : IOS_SITE_ID
        : DEFAULT_SITE_ID

      if (correctPageId && adId && sas && sas.cmd) {
        sas.cmd.push(function () {
          sas.call('onecall', {
            siteId,
            pageId: correctPageId,
            formats: [{ id: formatId, tagId: adId }],
            target: '',
            schain: '1.0,1!smartadserver.com,4104,1,BidRequest1'
          })
        })

        sas.cmd.push(function () {
          sas.render(adId)
        })
      }
    }
  }, [correctPageId, sasReady, isApp, forceAdRefresh])

  return (
    <AdComponent
      adId={adId}
      adPageId={correctPageId}
      sticky={sticky}
      formatId={formatId}
      hideMargin={hideMargin}
      hideTopMargin={hideTopMargin}
      hideBottomMargin={hideBottomMargin}
    />
  )
}

const AdContainer: AdProps = ({ formatId, desktop, mobile, ...rest }) => {
  const isTablet = isTabletScreen()
  const doDisplay = (isTablet && mobile) || (!isTablet && desktop)

  return <>{doDisplay ? <Ad formatId={formatId} {...rest} /> : null}</>
}

export default AdContainer
